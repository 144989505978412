import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'
import SuperTokens from 'supertokens-web-js'
import Session from 'supertokens-web-js/recipe/session'
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'
import EmailVerification from "supertokens-web-js/recipe/emailverification";

SuperTokens.init({
	appInfo: {
		appName: "viewversion",
		apiBasePath: "/auth",
		apiDomain: process.env.VUE_APP_API,
		//websiteDomain: window.location.origin,
	},
	recipeList: [
		Session.init(), // {autoAddCredentials: true,} (not sure we need?)
		EmailPassword.init(),
		EmailVerification.init({
			mode: "REQUIRED",
		}),
		//ThirdParty.init()
	],
})



const options = {
	path: `${process.env.VUE_APP_PATH ? process.env.VUE_APP_PATH : ''}/socket.io`,
	autoConnect: true,
	transports: ['websocket','polling'],
	reconnection: true,
	reconnectionAttempts: 9,
	reconnectionDelay: 6000
};

const socketConnection = SocketIO(process.env.VUE_APP_API, options)
socketConnection.on('connect', () => { console.log('WebSocket connected!') })
socketConnection.on('connect_error', (error) => { console.error('WebSocket connection error:', error) })
socketConnection.on('disconnect', (reason) => { console.log('WebSocket disconnected:', reason) })

store.commit('SET_SOCKET', socketConnection)

createApp(App).use(store).use(router).use(new VueSocketIO({
	debug: true,
	connection: socketConnection,
	vuex: {
		store,
		actionPrefix: "SOCKET_",
		mutationPrefix: "SOCKET_"
	}
})).mount('#app')
