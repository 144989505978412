import { createStore } from 'vuex'
import axios from 'axios'

function setValue(obj, keys, value) {
	let current = obj;

	for (let i = 0; i < keys.length - 1; i++) {
		if (current[keys[i]] == null) {
			return;
		}

		current = current[keys[i]];
	}

	current[keys[keys.length - 1]] = value;
}

export default createStore({
	state: {
		backend: process.env.VUE_APP_API + process.env.VUE_APP_PATH,
		banners: ['Master','Resize, First 3 Resizes','Resize, Subsequent Resizes','Master Alt Campaign',
		'Alt Campaign Versions'],
		userConnect: {
			email: null,
			role: null
		},
		clients: [],
		projects: [],
		socket: null
	},
	getters: {
		clientsAlpha: state => {
			return state.clients.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
		},
		clientSingle: state => (id) => {
			return state.clients.find(client => client._id === id)
		},
		projectsCreated: state => {
			return state.projects.sort((a, b) => (a.date.created < b.date.created) ? 1 : -1)
		},
		projectSingle: state => id => {
			return state.projects.find(project => project._id === id)
		},
		projectSingleByCampaign: state => id => {
			return state.projects.find(project => project.campaigns && project.campaigns.some(campaign => campaign._altid === id))
		},
		projectSingleByUnit: state => id => {
			for (const project of state.projects) {
				if(project.campaigns){
					for (const campaign of project.campaigns) {
						if (campaign.units && campaign.units.some(unit => unit._altid === id)) {
							return project;
						}
					}
				}
			}
			return null;
		}
	},
	mutations: {
		SET_SOCKET(state, socket) {
			state.socket = socket
		},
		setUser(state,payload){
			if(payload){
				//state.userConnect._id = payload._id || null
				state.userConnect.role = payload.role || null
				state.userConnect.email = payload.email || null
				state.userConnect.teams = payload.org || []
			}
		},
		setClients(state,payload){
			state.clients = payload
		},
		setClient(state,payload){
			let clientRec = state.clients.find(obj => obj._id === payload._id);
			let clientRecIndex = state.clients.findIndex(obj => obj._id === payload._id)

			if(clientRec == null){
				state.clients.push(payload)
			} else {
				state.clients[clientRecIndex] = payload
			}
		},
		setProjects(state,payload){
			state.projects = payload
		},
		setProject(state,payload){
			let projectRec = state.projects.find(obj => obj._id === payload._id);
			let projectRecIndex = state.projects.findIndex(obj => obj._id === payload._id)
			if(projectRec == null){
				state.projects.push(payload)
			} else {
				state.projects[projectRecIndex] = payload
			}
		},
		updateStore(state,payload){

			console.log('%%% updating store %%%')
			console.log(state,payload)
			console.log('%%%')

			let collFieldsDel = {
				'users': ['password','session'],
				'clients': [],
				'projects': []
			}

			if(state[payload.ns.coll] !== undefined && payload.documentKey._id){
				let rec = null;
					rec = state[payload.ns.coll].find(r => r._id === payload.documentKey._id)
				let recIndex = state[payload.ns.coll].findIndex(r => r._id === payload.documentKey._id)

				switch(payload.operationType) {
					case 'insert':
						for (const [key] of Object.entries(payload.fullDocument)) {
							if(collFieldsDel[payload.ns.coll].includes(key)){
								delete payload.fullDocument[key]
							}
						}

						state[payload.ns.coll].push(payload.fullDocument)
						break

					case 'update':
						var key_arr,
							new_key_arr = []

						for (const [key, value] of Object.entries(payload.updateDescription.updatedFields)) {
							key_arr = key.split('.')
							key_arr.forEach(function(item){
								if(isNaN(Number(item))){
									new_key_arr.push(item);
								} else {
									new_key_arr.push(Number(item));
								}
							})

							if(rec != null){
								setValue(rec, new_key_arr, value);
							}

							key_arr,
							new_key_arr = []
						}

						payload.updateDescription.removedFields.forEach(function (key) {
							delete rec[key]
						})
						break

					case 'delete':
						state[payload.ns.coll].splice(recIndex,1);
						break
				}
			}
		},
		SOCKET_CONNECT() {},
		SOCKET_DISCONNECT() {},
		SOCKET_MESSAGECHANNEL() {},
	},
	actions: {
		setUser(context,payload){
			context.commit('setUser',payload)
		},
		SOCKET_updateStore(context,payload){
			console.log('action to update store....')
			context.commit('updateStore',payload)
		},
		async checkUser({commit,state},payload){
			if(state.user == null ){
				try {
					const check_user = await axios.post(`${state.backend}/check-user`,{
						withCredentials: true, 
					})


					if(check_user.data){
						payload.org = check_user.data.org
						payload.email = check_user.data.email
						//payload._id = check_user.data.id
						payload.role = check_user.data.role

						commit('setUser',payload)

						if (state.socket) {
							state.socket.connect()
							state.socket.emit('join-room',payload.org)
							console.log(`Joined room: ${payload.org}`)
						} else {
							console.error('Socket not initialized')
						}
		
						console.log('socket above')
					}
		
				} catch (error) {
					console.error('Error:')
				}
			} else {
				console.log('not null')
			}
		},
		async loadClients(context){
			try {
				const get_clients = await axios.get(`${context.state.backend}/clients`,
						{ 
							params: {
								org: context.state.userConnect.teams,
							},
							withCredentials: true, 
						},
					)
				context.commit('setClients',get_clients.data)
			} catch (e) {
				console.log(e)
			}



		},
		async loadClient(context,payload){
			try {
				const get_client = await axios.get(`${context.state.backend}/client?i=${payload}`)
				context.commit('setClient',get_client.data)
			} catch (e) {
				console.log(e)
			}
		},
		async saveClient(context,payload){

			console.log('--save client--',payload);

			try {
				const edit_client = await axios.post(`${context.state.backend}/clients/edit`,payload)
				context.commit('setClient',payload.form)
				return edit_client
			} catch (e) {
				return e
			}
		},
		async loadProjects(context){
			try {
				const get_projects = await axios.get(`${context.state.backend}/projects`)
				context.commit('setProjects',get_projects.data)
			} catch (e) {
				console.log(e)
			}
		},
		async loadProject(context,payload){
			try {
				const get_project = await axios.get(`${context.state.backend}/project?i=${payload}`)
				context.commit('setProject',get_project.data)
			} catch (e) {
				console.log(e)
			}
		},
		async loadProjectByCampaign(context,payload){
			try {
				const get_project = await axios.get(`${context.state.backend}/campaign?i=${payload}`)
				context.commit('setProject',get_project.data)
			} catch (e) {
				console.log(e)
			}
		},
		async loadProjectByUnit(context,payload){
			console.log('loading project by unit...',payload)
			try {
				const get_project = await axios.get(`${context.state.backend}/unit?i=${payload}`)
				console.log('returning get project by unit...',get_project)
				context.commit('setProject',get_project.data)
			} catch (e) {
				console.log(e)
			}
		},
		async saveProject(context,payload){
			try {
				const edit_project = await axios.post(`${context.state.backend}/projects/edit`,payload)
				context.commit('setProject',payload.form)
				return edit_project
			} catch (e) {
				return e
			}
		},
		async editSubDoc(context,payload){
			try {
				const edit_subdoc = await axios.post(`${context.state.backend}${payload.route}`,payload)
				return edit_subdoc
			} catch (e) {
				return e
			}
		},
	}
})
