import { createRouter, createWebHistory } from 'vue-router'
import Session from 'supertokens-web-js/recipe/session'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/account/RegisterView.vue')
	},
	// {
	// 	path: '/verify-email',
	// 	name: 'Verify',
	// 	component: () => import('../views/account/VerifyView.vue')
	// },
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/account/LoginView.vue')
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../views/DashboardView.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('../views/account/LogoutView.vue')
	},
	{
		path: '/clients',
		name: 'Clients',
		component: () => import('../views/client/ClientsView.vue'),
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'Clients Index',
				component: () => import('../views/client/ClientIndex.vue')
			},
			{
				path: 'add',
				name: 'Add Client',
				component: () => import('../views/client/ClientAdd.vue'),
			},
			{
				path: 'edit/:id',
				name: 'Edit Client',
				component: () => import('../views/client/ClientEdit.vue')
			},
		]
	},
	{
		path: '/projects',
		name: 'Projects',
		component: () => import('../views/project/ProjectsView.vue'),
		children: [
			{
				path: '',
				name: 'Projects Index',
				component: () => import('../views/project/ProjectIndex.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'add',
				name: 'Add Project',
				component: () => import('../views/project/ProjectAdd.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'edit/:id',
				name: 'Edit Project',
				component: () => import('../views/project/ProjectEdit.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: ':id',
				name: 'Project Single',
				component: () => import('../views/project/ProjectSingle.vue'),
				children: [
					{
						path: 'add',
						name: 'Add Campaign',
						component: () => import('../views/project/CampaignAdd.vue'),
						meta: {
							requiresAuth: true
						}
					},
					{
						path: 'deliver',
						name: 'Deliver Project',
						component: () => import('../views/project/ProjectDeliver.vue'),
						meta: {
							requiresAuth: true
						}
					}
				]
			}
		]
	},
	{
		path: '/campaigns',
		name: 'Campaigns',
		component: () => import('../views/project/CampaignsView.vue'),
		children: [
			{
				path: '',
				name: 'Campaigns Index',
				component: () => import('../views/project/CampaignIndex.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'edit/:id',
				name: 'Edit Campaign',
				component: () => import('../views/project/CampaignEdit.vue'),
				meta: {
					requiresAuth: true
				}
			},
			{
				path: ':id',
				name: 'Campaign Single',
				component: () => import('../views/project/CampaignSingle.vue'),
				children: [
					{
						path: 'add',
						name: 'Add Unit',
						component: () => import('../views/project/UnitAdd.vue'),
						meta: {
							requiresAuth: true
						}
					},
					{
						path: 'backup',
						name: 'Add Backup',
						component: () => import('../views/project/UnitBackup.vue'),
						meta: {
							requiresAuth: true
						}
					}
				]
			}
		]
	},
		// {
		// 	path: 'campaigns/edit/:cid',
		// 	name: 'Edit Campaign',
		// 	component: () => import('../views/project/CampaignEdit.vue'),
		// 	meta: {
		// 		requiresAuth: true
		// 	}
		// },
	{
		path: '/units',
		name: 'Units',
		component: () => import('../views/project/UnitsView.vue'),
		children: [
			{
				path: '',
				name: 'Units Index',
				component: () => import('../views/project/UnitIndex.vue')
			},
			{
				path: ':id',
				name: 'Unit Single',
				component: () => import('../views/project/UnitSingle.vue')
			}
		]
	},
	{
		path: '/:catchAll(.*)',
		name: '404 Not Found',
		component: () => import('../views/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		const sessionExists = await Session.doesSessionExist()
		if (!sessionExists) {
			next('/login')
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
